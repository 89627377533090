import queryString from 'query-string';

export const CalculationParser = {
  parseQueryString() {
    const params = queryString.parse(window.location.search);

    // Default calculation
    let template = '{pat_weight} / ({pat_height} * {pat_height});';
    let values = {
      pat_weight: '80',
      pat_height: '1.80',
    };

    if (typeof params.input !== 'undefined' && typeof params.varnames !== 'undefined') {
      // Old calculation helper syntax: transform
      template = decodeURIComponent(params.input);
      const valueRegex = /([^= ]*)[ ]*=[ ]*([^;\n\r]*)[;\n\r]?[\n\r]*/g;

      let match;
      // eslint-disable-next-line no-cond-assign
      while ((match = valueRegex.exec(decodeURIComponent(params.varnames))) !== null) {
        const variableName = match[1];
        const rawValue = match[2];

        values[variableName] = rawValue.replace(/^"(.*)"$/, '$1').replace(/^'(.*)'$/, '$1');
      }
    } else if (typeof params.template !== 'undefined' && typeof params.values !== 'undefined') {
      // New syntax
      template = decodeURIComponent(params.template);
      values = JSON.parse(decodeURIComponent(params.values));
    }

    return { template, values };
  },

  generateCalculationUrl(template, values) {
    const parameters = queryString.stringify(
      {
        template: encodeURIComponent(template),
        values: JSON.stringify(values),
      },
      { strict: false },
    );

    return `${window.location.protocol}//${window.location.host}${window.location.pathname}?${parameters}`;
  },

  copyToClipboard(value) {
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  },
};

export default CalculationParser;
