export const CalculationExecutor = {
  calculationFrame: null,
  activeCalculation: null,
  queuedCalculations: [],

  start: function () {
    var me = CalculationExecutor;

    if (me.calculationFrame !== null) {
      return;
    }

    this.calculationFrame = document.createElement('iframe');
    this.calculationFrame.src = 'iframe.html';
    this.calculationFrame.sandbox = 'allow-scripts';
    this.calculationFrame.addEventListener(
      'load',
      function () {
        me.handleQueuedCalculations();
      },
      true
    );
    this.calculationFrame.style.display = 'none';
    document.body.appendChild(this.calculationFrame);
  },

  handleQueuedCalculations: function () {
    var me = CalculationExecutor;
    if (me.activeCalculation === null && me.queuedCalculations.length > 0) {
      me.activeCalculation = me.queuedCalculations.shift();

      let finishedCallback = me.activeCalculation.callback;
      let calculationFrame = me.calculationFrame;

      let listener = function (e) {
        if (
          (e.origin === 'null' || e.origin === window.location.origin) &&
          e.source === calculationFrame.contentWindow
        ) {
          finishedCallback(e.data);
          window.removeEventListener('message', listener);
          me.activeCalculation = null;
        }
      };

      window.addEventListener('message', listener);

      calculationFrame.contentWindow.postMessage(
        me.activeCalculation.code,
        '*'
      );
    }

    setTimeout(me.handleQueuedCalculations, 5);
  },

  queueCalculation: function (calculationToRun, finishedCallback) {
    CalculationExecutor.queuedCalculations.push({
      code: calculationToRun,
      callback: finishedCallback,
    });
  },

  /**
   * Taken from ExtJS
   * @param {number} value
   * @param {number} precision
   * @returns {number}
   */
  round: function (value, precision) {
    var result = Number(value);
    if (typeof precision == 'number') {
      precision = Math.pow(10, precision);
      result = Math.round(value * precision) / precision;
    }
    return result;
  },

  /**
   *
   * @param {String} value
   * @returns {string}
   */
  htmlEncode : function(value) {
    return !value ? value : String(value).replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;");
  },
};

export default CalculationExecutor;