import React from 'react';
import { Space, ValidationMessage } from '@castoredc/matter';

export const MissingVariables = ({ variables }) => (
  <div>
    <ValidationMessage size="maximum">
      Not all values for this calculation are available (yet).
    </ValidationMessage>
    <Space bottom="comfortable" />
    The following variables need to be completed:
    <ul>
      {variables.map(variable => (
        <li key={variable}>{variable}</li>
      ))}
    </ul>
  </div>
);

export default MissingVariables;
