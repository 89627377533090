import React from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';

export const CalculationEditor = ({ onChange, value, readOnly }) => (
  <AceEditor
    onChange={onChange}
    readOnly={readOnly}
    value={value}
    mode="javascript"
    theme="github"
    name="template"
    showPrintMargin={false}
    showGutter
    highlightActiveLine={!readOnly}
    width="100%"
    height="480px"
    setOptions={{
      showLineNumbers: true,
      tabSize: 2,
      useWorker: false,
    }}
  />
);

export default CalculationEditor;
