import React, { Component } from 'react';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import { Button, Spinner, Space, TextInput, Heading, Link } from '@castoredc/matter';
import axios from 'axios';

export default class GptPromptEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prompt: '',
            isLoading: false,
            enabled: false,
        };
    }

    handlePromptChange = (e) => {
        this.setState({
            prompt: e.target.value,
        });
    };

    generateTemplate = () => {
        const { prompt } = this.state;
        const { onChange } = this.props;

        this.setState({
            isLoading: true,
        });

        // eslint-disable-next-line
        grecaptcha.ready(() => {
            // eslint-disable-next-line
            grecaptcha.execute(process.env.REACT_APP_GREPCHA_KEY, { action: 'submit' }).then((token) => {
                const form = new FormData();
                form.append('prompt', prompt);
                form.append('recaptchaToken', token);

                axios.post(`${process.env.REACT_APP_API_BASE}/openai_calculation`, form)
                    .then((response) => {
                        const { data } = response;

                        this.setState({
                            isLoading: false,
                        }, () => {
                            onChange(data.calculation, data.templateVariables);
                        });

                        // eslint-disable-next-line no-console
                        console.log(response);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line no-console
                        console.log(error);

                        // eslint-disable-next-line eqeqeq
                        if (error.status == 422) {
                            // eslint-disable-next-line no-alert
                            alert(`Validation error: ${error.response.data.detail}`);
                        } else {
                            // eslint-disable-next-line no-alert
                            alert('An unexpected error occurred. Please try again or contact customer support.');
                        }

                        this.setState({
                            isLoading: false,
                        }, () => {
                            onChange('', []);
                        });
                    });
            });
        });
    };

    optIn = () => {
        this.setState({
            enabled: true,
        });
    }

    render() {
        const { prompt, isLoading, enabled } = this.state;

        return (
            <>
                <div className="PromptEditor">
                    {isLoading && (
                        <div className="Loader">
                            <Spinner />
                        </div>
                    )}

                    {!enabled && (
                        <div className="Consent">
                            <Heading type="Subsection">
                                The Castor AI engine is a beta feature
                            </Heading>
                            <p>
                                I understand this is an experimental (beta) feature which can
                                generate unexpected outcomes. Castor is not responsible for these
                                results.
                                <br />

                                By continuing I agree to Castor’s
                                {' '}
                                <Link href="https://www.castoredc.com/terms-of-use/">Terms of use</Link>
                                .
                            </p>
                            <Space bottom="comfortable" />

                            <Button buttonType="secondary" onClick={this.optIn}>
                                Opt-in and continue
                            </Button>
                        </div>
                    )}

                    <div className={isLoading ? 'isLoading' : ''}>
                        <TextInput
                            id="test"
                            multiline
                            resizable
                            value={prompt}
                            readOnly={isLoading || !enabled}
                            onChange={this.handlePromptChange}
                            inputSize="maximum"
                            rows="10"
                            maxLength={200}
                            autoFocus={enabled}
                        />
                    </div>
                </div>

                <Space bottom="condensed" />

                <Button
                    onClick={this.generateTemplate}
                    disabled={isLoading || !enabled || prompt.trim() === ''}
                    className={isLoading ? 'isLoading' : ''}
                >
                    Generate template
                </Button>
            </>
        );
    }
}
