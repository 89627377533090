import './App.scss';
import {
  Button,
  CastorNest,
  Heading,
  InputGroup,
  Link,
  Section,
  Separator,
  Space,
  Stack,
  TextContainer,
  TextStyle,
  ValidationMessage,
} from '@castoredc/matter';
import React, { Component } from 'react';
import CalculationExecutor from './helpers/CalculationExecutor';
import SyntaxErrors from './components/SyntaxErrors';
import MissingVariables from './components/MissingVariables';
import CalculationParser from './helpers/CalculationParser';
import CalculationEditor from './components/CalculationEditor';
import GptPromptEditor from './components/GptPromptEditor';

const esprima = require('esprima');

export default class App extends Component {
  constructor(props) {
    super(props);

    const { template, values } = CalculationParser.parseQueryString();

    this.state = {
      template,
      values,
      renderedTemplate: '',
      variables: [],
      missingValues: [],
      setEmptyToZero: false,
      allowEmpty: false,
      willCalculate: true,
      result: null,
      error: null,
      syntaxErrors: null,
    };

    this.timeOut = 0;
  }

  componentDidMount() {
    CalculationExecutor.start();
    this.parseTemplate();
  }

  handleTemplateChange = value => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }

    this.setState(
      {
        template: value,
      },
      () => {
        this.timeOut = setTimeout(this.parseTemplate, 300);
      },
    );
  };

  handleGptInput = (template, values) => {
    this.setState(
        {
          template,
          values,
        },
        () => {
          this.timeOut = setTimeout(this.parseTemplate, 300);
        },
    );
  }


  handleValueChange = e => {
    const { values } = this.state;

    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }

    this.setState(
      {
        values: {
          ...values,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        this.timeOut = setTimeout(this.parseTemplate, 300);
      },
    );
  };

  parseTemplate = () => {
    const { template } = this.state;

    const usedVariables = [...new Set(template.match(/{[a-zA-Z][a-zA-Z0-9_.,\s]+}/gi))];

    const variableNames = usedVariables.map(variable => variable.replace(/{/, '').replace(/}/, ''));

    const setEmptyToZero = !!template.match(/##setemptytozero##/gi);
    const allowEmpty = !!template.match(/##allowempty##/gi);

    this.setState(
      {
        variables: variableNames,
        setEmptyToZero,
        allowEmpty,
      },
      this.fillTemplate,
    );
  };

  fillTemplate = () => {
    const { template, variables, values, setEmptyToZero, allowEmpty } = this.state;

    let templateToExecute = template;
    let calculate = true;
    const missingVariables = [];

    variables.forEach(variable => {
      let value;

      if (typeof values[variable] !== 'undefined' && values[variable] !== '') {
        value = values[variable];
      } else if (setEmptyToZero) {
        value = 0;
      } else if (allowEmpty) {
        value = "'NA'";
      } else {
        calculate = false;
        missingVariables.push(variable);
      }

      templateToExecute = templateToExecute.replace(new RegExp(`{${variable}}`, 'g'), value);
    });

    this.setState(
      {
        renderedTemplate: templateToExecute,
        willCalculate: calculate,
        missingValues: missingVariables,
      },
      () => {
        if (calculate) {
          this.validateAndExecuteTemplate(templateToExecute);
        }
      },
    );
  };

  validateAndExecuteTemplate = templateToExecute => {
    try {
      const syntax = esprima.parse(templateToExecute, {
        tolerant: true,
        loc: true,
      });

      if (syntax.errors > 0) {
        this.handleSyntaxErrors(
          syntax.errors.map(error => ({
            line: error.lineNumber,
            message: error.description,
          })),
        );
      } else {
        this.handleSyntaxSuccess();
        CalculationExecutor.queueCalculation(templateToExecute, this.handleResult);
      }
    } catch (e) {
      this.handleSyntaxErrors([
        {
          line: e.lineNumber || null,
          message: e.description || e.toString(),
        },
      ]);
    }
  };

  handleSyntaxErrors = errors => {
    this.setState({
      syntaxErrors: errors,
    });
  };

  handleSyntaxSuccess = () => {
    this.setState({
      syntaxErrors: null,
    });
  };

  handleResult = iframeResult => {
    if (iframeResult.evaluated === true) {
      let calculatedValue = iframeResult.value;

      if (typeof iframeResult.value === 'number') {
        calculatedValue = CalculationExecutor.round(iframeResult.value, 2);
        // Convert to string, so it compares to values from the backend.
        calculatedValue += '';
      }

      if (typeof iframeResult.value === 'undefined') {
        calculatedValue = '';
      }

      this.setState({
        result: calculatedValue,
        error: null,
      });
    } else {
      this.setState({
        result: null,
        error: CalculationExecutor.htmlEncode(iframeResult.exception),
      });
    }
  };

  copyLink = () => {
    const { template, values } = this.state;

    const url = CalculationParser.generateCalculationUrl(template, values);
    CalculationParser.copyToClipboard(url);
  };

  copyCalculation = () => {
    const { template } = this.state;

    CalculationParser.copyToClipboard(template);
  };

  render() {
    const {
      template,
      renderedTemplate,
      variables,
      values,
      willCalculate,
      missingValues,
      result,
      error,
      syntaxErrors,
    } = this.state;

    return (
      <div className="App">
        <div id="Header">
          <div id="Logo">
            <CastorNest height={40} />
            <span>Calculation helper</span>
          </div>

          <div id="Links">
            <Link href="https://www.castoredc.com/terms-of-use/">Terms of use</Link>
            <Link href="https://www.castoredc.com/privacy-policy/">Privacy policy</Link>
            <Link href="https://www.castoredc.com/security-statement/">Security statement</Link>
          </div>
        </div>
        <div id="Body">
          <div id="CalculationTemplate">
            <Section>
              <div className="Step">
                <div className="StepHeader">
                  <div className="StepNumber">1</div>
                  <div className="StepTitle">Generate</div>
                </div>
                <div className="StepDescription">
                  Write your own Javascript or use our AI to create a calculation template.
                </div>
              </div>

              <TextContainer>
                <Heading type="Section">
                  Use AI to create calculations for Castor
                  <div className="BetaBadge">Beta</div>
                </Heading>
                <a href="https://helpdesk.castoredc.com/en_US/general-calculation-templates/ai-prompt-templates/" rel="noopener noreferrer" target="_blank">You can find examples here!</a>
              </TextContainer>

              <Space bottom="comfortable" />

              <GptPromptEditor onChange={this.handleGptInput} />
            </Section>

            <Space bottom="comfortable" />

            <Section>
              <TextContainer>
                <Heading type="Section">Calculation template</Heading>
                Here you see the generated template. Feel free to edit it.
              </TextContainer>

              <Space bottom="comfortable" />

              <CalculationEditor value={template} onChange={this.handleTemplateChange} />

              <Stack alignment="end" distribution="trailing" spacing="none">
                <Button onClick={this.copyCalculation} icon="copy" buttonType="contentOnly">
                  Copy calculation
                </Button>
              </Stack>
            </Section>
          </div>

          <div id="Variables">
            <div className="Step">
              <div className="StepHeader">
                <div className="StepNumber">2</div>
                <div className="StepTitle">Test</div>
              </div>
              <div className="StepDescription">
                Here, you can fill in test values to ensure the calculation works correctly.
                Make sure you test thoroughly!
              </div>
            </div>

            <Section>
              <TextContainer>
                <Heading type="Section">Define your variables</Heading>
                Here you define the variables that you want to use in your calculation.
              </TextContainer>

              <Space bottom="comfortable" />

              {variables.map(variable => (
                <div className="variable" key={variable}>
                  <InputGroup
                    labelText={variable}
                    name={variable}
                    value={values[variable] ? values[variable] : ''}
                    onChange={this.handleValueChange}
                  />

                  <Space bottom="comfortable" />
                </div>
              ))}
            </Section>
          </div>

          <div id="Outcome">
            <div className="Step">
              <div className="StepHeader">
                <div className="StepNumber">3</div>
                <div className="StepTitle">Check</div>
              </div>
              <div className="StepDescription">
                Here you will see the results for the input values that you provided.
              </div>
            </div>
            <Section>
              <div>
                <TextContainer>
                  <Heading type="Section">Syntax check</Heading>
                  If there are any issues with your script, they will appear here.
                </TextContainer>

                <Space bottom="comfortable" />

                {syntaxErrors ? (
                  <SyntaxErrors errors={syntaxErrors} />
                ) : (
                  <ValidationMessage size="maximum">
                    Your code is syntactically valid.
                  </ValidationMessage>
                )}
              </div>

              <Separator />

              <div>
                <TextContainer>
                  <Heading type="Section">Result</Heading>
                  This is the output of the calculation.
                </TextContainer>

                <Space bottom="comfortable" />

                {willCalculate ? (
                  <div>
                    {error && (
                      <ValidationMessage type="error" size="maximum">
                        <strong>The calculation generated the following error:</strong>
                        <br />
                        {error}
                      </ValidationMessage>
                    )}
                    <TextStyle variation="code">{result}</TextStyle>
                  </div>
                ) : (
                  <MissingVariables variables={missingValues} />
                )}
              </div>

              <Separator />

              <div id="Final">
                <TextContainer>
                  <Heading type="Section">Final formula</Heading>
                  This is what your final formula will look like.
                </TextContainer>

                <Space bottom="comfortable" />

                <CalculationEditor value={renderedTemplate} readOnly />
              </div>

              <Stack alignment="end" distribution="trailing" spacing="none">
                <Button onClick={this.copyLink} icon="copy" buttonType="contentOnly">
                  Copy link to this page
                </Button>
              </Stack>
            </Section>
          </div>
        </div>
      </div>
    );
  }
}
